import React, { Component } from "react"
import styled from "styled-components"
import BlockContent from "@sanity/block-content-to-react"
import Img from "gatsby-image"
import Slider from "react-slick"

const sliderSettings = {
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  arrows: true,
  autoplaySpeed: 4500,
}

const BlockContainer = styled.section`
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    display: flex;
  }
`

const TextContainer = styled.div`
  color: ${({ theme }) => theme.backgroundLight};
  background: ${({ theme }) => theme.colorText};
  padding: ${({ theme }) => `calc(${theme.paddingBox} * 2)`};
  position: relative;
  z-index: 1;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    flex: 0 0 33%;
    ${({ theme, isEven }) =>
      isEven
        ? `
    margin: ${theme.paddingBox};
    margin-left: -${theme.paddingBox};
    `
        : `
    order: -1;
    margin: ${theme.paddingBox};
    margin-right: -${theme.paddingBox};
    `}
  }
`

const SliderAbsolute = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
`

const SliderContainer = styled.div`
  position: relative;
  z-index: 0;
  height: 40vh;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    width: 66%;
    height: auto;
  }
`

const StyledImg = styled(Img)`
  height: 100%;
  width: 100%;
  img {
    object-fit: cover;
    object-position: center center;
    height: 100%;
    width: 100%;
  }
`

const BlocFive = ({ rawContent, imgs, isEven }) => (
  <>
    <BlockContainer>
      <SliderContainer>
        <SliderAbsolute>
          <Slider {...sliderSettings}>
            {imgs.map(image => (
              <StyledImg
                key={image.asset.fluid.src}
                fluid={image.asset.fluid}
                alt={""}
              />
            ))}
          </Slider>
        </SliderAbsolute>
      </SliderContainer>
      <TextContainer isEven={isEven}>
        <BlockContent blocks={rawContent} />
      </TextContainer>
    </BlockContainer>
  </>
)

export default BlocFive
