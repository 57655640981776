import React from 'react'
import styled from 'styled-components'
import { Structure } from '@lib/simba/_statics'
import { graphql } from 'gatsby'
import Seo from '@components/Seo'
import { ARTICLE } from '@utils/get-schemas'
import getSeo from '@utils/get-seo'
import Chooser from '@lib/simba/content-blocs'
import Divbox from '@lib/simba/divbox'
import BlockContent from '@sanity/block-content-to-react'
import BackgroundImage from 'gatsby-background-image'

const Whole = styled.div`
    position: relative;
    z-index: 8999;
    p {
        margin: 0;
    }
`
const LayoutArticle = styled.div`
    padding: ${({theme}) => theme.paddingBox};
    position: relative;
    width: 95vw;
    margin: auto;
    background: white;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}){
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        top: -3rem;
        width: 75vw;
    }
`
const Head = styled.div`
    position: relative;
    z-index: 1;
    padding: 0;
    background: white;
    align-items: center;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}){
        width: 100vw;
    }
`
const Resume = styled.div`
    position: absolute; 
    top: 50%; left: 50%; 
    transform: translate(-50%, -50%); 
    background: white;
    width: 95%;
    opacity: 0.9;
    padding: ${({theme}) => theme.paddingBox};
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    & p:last-child {
        margin-bottom : 0;
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        padding: calc(${({theme}) => theme.paddingBox}*2);
        width: 50%;
    }
    
`
const Title = styled.h1`
    color: ${({ theme }) => theme.colors.secondary};
    text-align: center;
`

const ImgContainer = styled.div`
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 75vh;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    }
`
const StyledBackgroundImg = styled(BackgroundImage)`
    display:block;
    width: 100%;
    height: 100%;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    }
`

export default ({ data }) => (
    <Structure hideFooterArticle={true}>
        <Seo seo={getSeo(data.article, ARTICLE)} />
        <Head>
            <ImgContainer>
                <StyledBackgroundImg
                    fluid={data.article.cover.asset.fluid}
                    imgStyle={{
                        objectFit: 'cover',
                        objectPosition: 'center center'
                    }} 
                />
            </ImgContainer>
            <Resume>
                <Title>{data.article.title}</Title>
                <BlockContent blocks={data.article._rawContent}></BlockContent>
            </Resume>
        </Head>
        <Whole>      
            {
                data.contents.nodes.length !== 0
            ?
                <LayoutArticle>
                    {data.contents.nodes.map(content => {
                        return (
                            <Divbox>
                                <Chooser
                                    typebloc={content.typebloc}
                                    rawContent={content._rawContent} 
                                    imgs={content.images}
                                    url={content._rawLinks}
                                />
                            </Divbox>
                        )
                    })}
                </LayoutArticle>
            : 
                <></>
            }  
        </Whole>
    </Structure>
)

export const query = graphql`
    query ArticleList($id: String) {
        article: sanityContenu(id: {eq: $id}) {
            id
            title
            resume
            _rawContent(resolveReferences: {maxDepth: 5})
            categories {
                title
            }
            seo {
                title
                keywords
                description
            }
            cover {
                asset {
                    url
                    metadata {
                        dimensions {
                            height
                            width
                        }
                    }
                    fluid(maxWidth: 1280) {
                        ...GatsbySanityImageFluid
                    }
                }
            }
        }
        contents: allSanityContentbloc(
            sort: {fields: order}
            filter: {
                linkedcontent: {id: {eq: $id}}
            }
        ) {
            nodes {
                typebloc
                _rawLinks
                _rawContent(resolveReferences: {maxDepth: 5})
                images {
                    asset {
                        url
                        fluid(maxWidth: 1280) {
                            ...GatsbySanityImageFluid
                        }
                    }
                }
            }
        }
        sidebar: sanityContenu(
            id: {ne: $id}
            categories: {elemMatch: {slug: {current: {eq: "actualites"}}}}
        ) {
            title
            slug {
                current
            }
            cover {
                asset {
                    fluid(maxWidth: 400) {
                        ...GatsbySanityImageFluid
                    }
                }
            }
        }
    }
`