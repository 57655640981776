import React from 'react'
import styled from 'styled-components'
import BlockContent from '@sanity/block-content-to-react'
import Img from 'gatsby-image'

const Container = styled.div`
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        position: relative;
        display: flex;
        justify-content: center;
    }
`
const TextContainer = styled.div`
    padding: ${({ theme }) => `calc(${theme.paddingBox}/4)`} ${({ theme }) => `calc(${theme.paddingBox}*2)`};
    position: relative;
    z-index: 1;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        padding: 0 ${({ theme }) => `calc(${theme.paddingBox})`};
        background: white;
        max-width: ${({ theme }) => theme.maxWidth};
        width: 35vw;
    }
`


const BlocFive = ({ rawContent }) => (
    <Container>
        <TextContainer>
            <BlockContent blocks={rawContent} />
        </TextContainer>
    </Container>
)

export default BlocFive
