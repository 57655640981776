import React from "react"
import styled from "styled-components"
import Separator from '@lib/simba/separator'


const BlockContainer = styled.section`
    display: none;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    display: flex;
    justify-content: center;
  }
`


const BlocSeparator = () => (
    <BlockContainer>
      <Separator />
    </BlockContainer>
)

export default BlocSeparator
