import React from 'react'
import styled from 'styled-components'
import BlockContent from '@sanity/block-content-to-react'
import Img from 'gatsby-image'
import Slider from 'react-slick'

const sliderSettings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
    autoplaySpeed: 4500,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            variableWidth: false
          }
        }
    ]
}

const Container = styled.div`
    min-height: 50vh;
`
const ImgContainer = styled.div`
    height: 100vh;
    padding: 0;
`

const StyledImg = styled(Img)`
    height: 100%;
    max-width: 100%;
    img {
        max-width: 100%;
    }
`

const TextContainer = styled.div`
    background: rgba(255, 255, 255, 0.95);
    position: relative;
    z-index: 2;
    padding: ${({ theme }) => theme.paddingBox};
    margin: ${({ theme }) => theme.paddingBox};
    margin-top: -45vh;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        max-width: ${({ theme }) => theme.maxWidth};
        padding: ${({ theme }) => `calc(${theme.paddingBox}*2)`};
        margin-left: auto;
        margin-right: auto;
        margin-top: -33vh;
    }
`
const SliderContainer = styled.div`
    position: relative;
    z-index: 1;
`


const BlocTwo = ({ rawContent, imgs }) => (
    <Container>
        <SliderContainer>
            <Slider {...sliderSettings}>
                {imgs.map(image => (
                    <ImgContainer key={image.asset.fluid.src} style={{width: image.asset.fluid.aspectRatio * 650, maxWidth: '100vw' }}>
                        <StyledImg fluid={image.asset.fluid} alt={''} />
                    </ImgContainer>
                ))}
            </Slider>
        </SliderContainer>
        <TextContainer>
            <BlockContent blocks={rawContent} />
        </TextContainer>
    </Container>
)

export default BlocTwo
