import React from 'react'
import styled from 'styled-components'
import BlockContent from '@sanity/block-content-to-react'
import Img from 'gatsby-image'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        flex-direction: row;
        justify-content: inherit;
        align-items: inherit;
    }
`
const TextContainer = styled.div`
    background: ${({ theme }) => theme.colorText};
    color: white;
    padding: ${({ theme }) => `calc(${theme.paddingBox}*2)`};
    flex: 0 0 100%;
    border-radius: 1rem;
    order: 2;
    margin: -20px -10px 0px -10px;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        flex: 0 0 45%;
        order: -1;
        margin: 40px -40px 40px 0px;
        z-index: 10;
        border-radius: 1rem;
    }
`
const Illustration = styled.div`
    position: relative;
    width: 100%;
`
const ImgContainer = styled.div`
    position: relative;
    top:0; left:0;
    z-index: 9;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        position: absolute;
    }
`

const StyledImg = styled(Img)`
    border-radius: 1rem;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        object-fit: cover;
        object-position: center center;
        width: 100%;
        height: 100%;
    }
`

const BlocOne = ({ rawContent, imgs }) => (
    <Container> 
        <TextContainer>
            <BlockContent blocks={rawContent} />
        </TextContainer>
        <Illustration>
            {imgs.slice(0, 1).map(img => (
                <ImgContainer key={img.asset.url}>
                    <StyledImg
                        fluid={img.asset.fluid}
                    />
                </ImgContainer>
            ))}
        </Illustration>
    </Container>
)

export default BlocOne