import React from 'react'
import styled from 'styled-components'
import BlockContent from '@sanity/block-content-to-react'
import Img from 'gatsby-image'
import Slider from 'react-slick'

const sliderSettings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    variableWidth: true,
    arrows: false,
    autoplaySpeed: 4500,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            variableWidth: false,
            fade: true
          }
        }
    ]
}

const Container = styled.div`
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        min-height: 80vh;
        position: relative;
        display: flex;
        justify-content: center;
    }

`
const ImgContainer = styled.div`
    height:20vh;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        height: 80vh;
        width: 60vw;
    }
`

const StyledImg = styled(Img)`
    max-height: 100%;
    max-width: 100%;
    img {
            max-width: 100%;
        }
`

const TextContainer = styled.div`
    padding: ${({ theme }) => `calc(${theme.paddingBox}*2)`};
    position: relative;
    z-index: 1;
    order : -1;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        padding: ${({ theme }) => `calc(${theme.paddingBox})`};
        background: white;
        max-width: ${({ theme }) => theme.maxWidth};
        width: 35vw;
        min-height: 50vh;
    }
`
const SliderContainer = styled.div`
    height: 20vh;
    width: 100%;
    max-width:55vw;
    margin: 0 auto;  
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        width: 20%;
        position:relative;
        margin: 0;  
    }
`


const BlocFive = ({ rawContent, imgs }) => (
    <Container>
        <SliderContainer>
            <Slider {...sliderSettings}>
                {imgs.map(image => (
                    <ImgContainer key={image.asset.fluid.src} style={{width: image.asset.fluid.aspectRatio * 100, maxWidth: '20vw' }}>
                        <StyledImg fluid={image.asset.fluid} alt={''} />
                    </ImgContainer>
                ))}
            </Slider>
        </SliderContainer>
        <TextContainer>
            <BlockContent blocks={rawContent} />
        </TextContainer>
    </Container>
)

export default BlocFive
