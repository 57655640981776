import React from 'react'
import styled from 'styled-components'
import BlockContent from '@sanity/block-content-to-react'
import Img from 'gatsby-image'


const Container = styled.div`
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        max-width: ${({ theme }) => theme.maxWidth};
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`
const ImgContainer = styled.div`
    margin: ${({ theme }) => `0 calc(${theme.paddingBox})`};
    height: 100%;
    margin-bottom: ${({ theme }) => `calc(-${theme.paddingBox}*2)`};
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        width: 100vw;
        height: 100%;
        margin: 0;
        margin-left: ${({ theme }) => `calc(-${theme.paddingBox}*1)`};
        position: relative;
        z-index: 1;
        flex: 0 0 35vw;
    }
`
const TextContainer = styled.div`
    background: ${({ theme }) => theme.colorText};
    color: white;
    padding: ${({ theme }) => `calc(${theme.paddingBox}*2)`};
    padding-top: ${({ theme }) => `calc(${theme.paddingBox}*4)`};
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        padding: ${({ theme }) => `calc(${theme.paddingBox}*4)`};
        order: -1;
        position: relative;
        z-index: 2;
    }
`

const StyledImg = styled(Img)`
    width: 100%;
    height: 100%;
`

const BlocFour = ({ rawContent, imgs, url }) => (
    console.log({url}),
    <Container>
        {imgs.slice(0, 1).map(img => (
            <ImgContainer key={img.asset.url}>
                <a rel="noopener noreferrer" href={url[0].url} target="_blank" >
                        <StyledImg
                        fluid={img.asset.fluid}
                        imgStyle={{
                            objectFit: 'cover'
                        }} 
                    />
                </a>
            </ImgContainer>
        ))}
        <TextContainer>
            <BlockContent blocks={rawContent} />
        </TextContainer>
    </Container>
)

export default BlocFour