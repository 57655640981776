import React from 'react'
import styled from 'styled-components'

const Separator = styled.div`
    margin-bottom: ${({ theme }) => theme.paddingBox};
    height: 20px;
    position: relative;
    line-height: 20px;
    text-align: center;
    &::before, &::after {
        content: '';
        height: 1px;
        width: 66px;
        left: 50%;
        top: 11px;
        margin-left: -80px;
        position: absolute;
        background: ${({ theme, color }) => color ? color : theme.colorText};
    }
    &::after {
        left: auto;
        right: 50%;
        margin-left: auto;
        margin-right: -80px;
    }
`
const SeparatorInn = styled.span`
    width: 7px;
    height: 7px;
    line-height: 7px;
    position: relative;
    border: 1px solid ${({ theme, color }) => color ? color : theme.colorText};
    display: inline-block;
    transform: rotate(45deg);
    &::before, &::after {
        position: absolute;
        content: '';
        width: 7px;
        height: 7px;
        border: 1px solid ${({ theme, color }) => color ? color : theme.colorText};
        display: block;
    }
    &::before {
        left: -8px;
        top: 6px;
    }
    &::after {
        right: -8px;
        top: -8px;
    }
`

export default ({ color }) => (
    <Separator color={color}>
        <SeparatorInn color={color} />
    </Separator>
)