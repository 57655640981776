import React from 'react'
import styled from 'styled-components'
import BlockContent from '@sanity/block-content-to-react'
import Img from 'gatsby-image'
import Slider from 'react-slick'

const sliderSettings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    variableWidth: true,
    arrows: false,
    autoplaySpeed: 4500,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            variableWidth: false,
            fade: true
          }
        }
    ]
}

const Container = styled.div`
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        min-height: 50vh;
        position: relative;
        display: flex;
        justify-content: flex-end;
    }

`
const ImgContainer = styled.div`
    height: 50vh;
`

const StyledImg = styled(Img)`
    height: 100%;
    max-width: 100%;
    img {
        max-width: 95%;
    }
`

const TextContainer = styled.div`
    padding: ${({ theme }) => `calc(${theme.paddingBox}*1)`}};
    position: relative;
    z-index: 1;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        background: white;
        max-width: ${({ theme }) => theme.maxWidth};
        width: 35vw;
        min-height: 50vh;
        margin-right: ${({ theme }) => `calc((100vw - ${theme.breakpoints.desktop})/4)`};
    }
`
const SliderContainer = styled.div`
    height: 50vh;
    width: 100%;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
    }
`


const BlocThree = ({ rawContent, imgs }) => (
    <Container>
        <SliderContainer>
            <Slider {...sliderSettings}>
                {imgs.map(image => (
                    <ImgContainer key={image.asset.fluid.src} style={{width: image.asset.fluid.aspectRatio * 500, maxWidth: '100vw' }}>
                        <StyledImg fluid={image.asset.fluid} alt={''} />
                    </ImgContainer>
                ))}
            </Slider>
        </SliderContainer>
        <TextContainer>
            <BlockContent blocks={rawContent} />
        </TextContainer>
    </Container>
)

export default BlocThree
