import React from 'react'
import BlocOne from './BlocOne'
import BlocTwo from './BlocTwo'
import BlocThree from './BlocThree'
import BlocFour from './BlocFour'
import BlocFive from './BlocFive'
import BlocSix from './BlocSix'
import BlocOneInversed from './BlocOneInversed'
import BlocFiveInversed from './BlocFiveInversed'
import BlocSeparator from './BlocSeparator'


const dico = {
    1: BlocOne,
    2: BlocTwo,
    3: BlocThree,
    4: BlocFour,
    5: BlocFive,
    6: BlocSix,
    7: BlocOneInversed,
    8: BlocFiveInversed,
    9: BlocSeparator
}

const Chooser = ({ typebloc, ...props }) => {
    const Element = dico[typebloc]
    return <Element {...props} />
}

export default Chooser